import { deleteRequest, getDownloadRequest, getRequest, patchRequest, postRequest, putRequest } from "./apiRequest"
import { adminEndPoints, arrrearEndPoints, authEndPoints, bonusEndPoints, commonEndPoints, companyEndPoints, leaveEndPoints, payoutEndPoints, payslipEndPoints, userEndPoints, utilityEndPoints } from "./endPoints"

//To Reset Password
export const resetPasswordApi = (payload) => {
  return patchRequest(authEndPoints.RESET_PASSWORD, payload)
}

//To login in application
export const signInApi = (payload) => {
  return postRequest(authEndPoints.LOGIN, payload)
}

//To verify email
export const verifyEmailApi = (payload) => {
  return patchRequest(authEndPoints.VERIFY_EMAIL, payload);
}

//To forget password
export const forgotPasswordApi = (payload) => {
  return patchRequest(authEndPoints.FORGOT_PASSWORD, payload)
}

//To chnage password
export const changePasswordApi = (payload) => {
  return patchRequest(userEndPoints.CHANGE_PASSWORD, payload)
}

//To get user details
export const getUserDetailsApi = () => {
  return getRequest(userEndPoints.USER);
}

//To update personal details
export const updateUserDetailsApi = (payload) => {
  return putRequest(userEndPoints.USER, payload)
}

//To add user emergency contact
export const addUserEmergencyContactApi = (payload) => {
  return postRequest(userEndPoints.EMERGENCY_CONTACTS, payload)
}

//To edit user emergency contact
export const editUserEmergencyContactApi = (payload) => {
  return putRequest(userEndPoints.EMERGENCY_CONTACTS, payload);
}

//To delete user emergency contact
export const deleteUserEmergencyContactApi = (id) => {
  return deleteRequest(userEndPoints.EMERGENCY_CONTACTS + '/' + id)
}

//To get alternate contact
export const getUserAlternateContactApi = () => {
  return getRequest(userEndPoints.ALTERNATE_CONTACT);
}

//To add alternate contact
export const addUserAlteranateContactApi = (payload) => {
  return postRequest(userEndPoints.ALTERNATE_CONTACT, payload)
}

//To Edit alternate contact
export const editUserAlteranateContactApi = (payload) => {
  return putRequest(userEndPoints.ALTERNATE_CONTACT, payload)
}

//To delete alternate contact
export const deleteUserAlteranateContactApi = (id) => {
  return deleteRequest(userEndPoints.ALTERNATE_CONTACT + "/" + id)
}

//To Update Bank details
export const editUserBankDetailsApi = (payload) => {
  return putRequest(userEndPoints.USER_BANK, payload)
}

//To add new employee
export const addEmployeeApi = (payload) => {
  return postRequest(companyEndPoints.EMPLOYEE, payload)
}

export const addLeadToEmployeeApi = (payload) => {
  return postRequest(companyEndPoints.EMPLOYEE_LEAD, payload)
}

export const removeLeadFromEmployeeApi = (payload) => {
  return putRequest(companyEndPoints.EMPLOYEE_LEAD, payload)
}
export const addAsManagerApi = (payload) => {
  return putRequest(companyEndPoints?.EMPLOYEE_MANAGER, payload)
}

//to get all users
export const getAllUsersApi = (payload) => {
  return getRequest(companyEndPoints.USERS + "?" + (payload ? payload : ''))
}

//To Edit employee
export const editEmployeeApi = (payload) => {
  return putRequest(companyEndPoints.EMPLOYEE, payload);
}

//to update emplyee roles
export const updateRolesApi = (payload) => {
  return patchRequest(companyEndPoints.EMPLOYEE_ROLES, payload);
}

//To updateStatus of employee
export const updateEmployeeStatusApi = (paylad) => {
  return patchRequest(companyEndPoints.EMPLOYEE_STATUS, paylad);
}

//to get employees
export const getEmployeesApi = (payload) => {
  return getRequest(companyEndPoints.EMPLOYEE + "?" + (payload ? payload : ''))
}

//To get assigned employees
export const getAssignedEmployeesApi = (payload) => {
  return getRequest(companyEndPoints.ASSIGNED_EMPLOYEE + "?" + (payload ? payload : ''))
}

//to get particular employee 
export const getEmployeeApi = (id) => {
  return getRequest(companyEndPoints.EMPLOYEE + '/' + id)
}

//to add new role
export const addCompanyRoleApi = (payload) => {
  return postRequest(companyEndPoints.ROLE, payload)
}

//to edit role
export const editCompanyRoleApi = (payload) => {
  return putRequest(companyEndPoints.ROLE + '/' + payload.roleId, payload)
}

//to dete role
export const deleteCompanyRoleApi = (roleId) => {
  return deleteRequest(companyEndPoints.ROLE + '/' + roleId)
}

//To get all companies
export const getAllCompanyApi = (payload) => {
  return getRequest(adminEndPoints.COMPANY_ALL + "?" + (payload ? payload : ''))
}

//to get comapny details
export const getCompanyDetailsApi = () => {
  return getRequest(companyEndPoints.COMPANY)
}

export const getCompanyByIdApi = (payload) => {
  return getRequest(companyEndPoints.COMPANY + '/' + payload)
}

//to edit companyDetails
export const editCompanyDetailsApi = (payload) => {
  return putRequest(companyEndPoints.COMPANY, payload)
}

//To edit company settings
export const editCompanyScheduleApi = (payload) => {
  return putRequest(companyEndPoints.COMPANY_SETTING, payload)
}

//to add alternate contact of company
export const getCompanyAlteranateContactApi = (payload) => {
  return getRequest(companyEndPoints.ALTERNATE_CONTACT, payload)
}

//to add alternate contact of company
export const addCompanyAlteranateContactApi = (payload) => {
  return postRequest(companyEndPoints.ALTERNATE_CONTACT, payload)
}

//to edit alternate contact of company
export const editCompnayAlternateContactApi = (payload) => {
  return putRequest(companyEndPoints.ALTERNATE_CONTACT, payload)
}

//to delete alternate contact of company
export const deleteCompanyAlternateContactApi = (id) => {
  return deleteRequest(companyEndPoints.ALTERNATE_CONTACT + '/' + id)
}

//to add new company
export const addNewCompanyApi = (payload) => {
  return postRequest(adminEndPoints.COMPANY, payload)
}

//To get leave history by id
export const getEmployeeLeaveHistoryApi = (userId) => {
  return getRequest(leaveEndPoints.LEAVE + '/' + userId)
}

//To aaply leave
export const applyLeaveApi = (payload) => {
  return postRequest(leaveEndPoints.LEAVE, payload)
}

//To edit applied Leave
export const editLeaveApi = (payload) => {
  return putRequest(leaveEndPoints.LEAVE, payload)
}

//To cancel leave
export const cancelLeaveApi = (paylad) => {
  return patchRequest(leaveEndPoints.LEAVE_CANCEL, paylad)
}

//to upload files 
export const uploadProfileImageApi = (payload) => {
  return postRequest(utilityEndPoints.PROFILE, payload)
}

//to upload files 
export const uploadLogoApi = (payload) => {
  return postRequest(utilityEndPoints.LOGO, payload)
}

//to upload files 
export const uploadCalendarApi = (payload) => {
  return postRequest(utilityEndPoints.CALENDAR, payload)
}



//to get role list
export const getRolesApi = () => {
  return getRequest(commonEndPoints.ROLE)
}

//to get users leaves
export const getUserLeavesApi = () => {
  return getRequest(leaveEndPoints.LEAVE);
}

//To get users leave Info
export const getUsersLeaveInfoApi = () => {
  return getRequest(leaveEndPoints.LEAVE_INFO)
}

export const getEmployeeLeaveReportApi = (userId) => {
  return getRequest(leaveEndPoints.LEAVE + "/" + userId + '/info')
}

//To add leave to all users
export const addLeaveToAllUsersApi = (payload) => {
  return postRequest(leaveEndPoints.ALL_BALANCE, payload)
}

//To add leave to selected users
export const addLeaveToSelectedUsersApi = (payload) => {
  return postRequest(leaveEndPoints.USERS_BALANCE, payload)
}

//To add leave to all users
export const addLeaveToSingleUserApi = (payload) => {
  return postRequest(leaveEndPoints.USER_BALANCE, payload)
}

//To approve leave request
export const approveLeaveRequestApi = (payload) => {
  return patchRequest(leaveEndPoints.LEAVE_APPROVE, payload)
}

//To reject leave request 
export const rejectLeaveRequestApi = (payload) => {
  return patchRequest(leaveEndPoints.LEAVE_REJECT, payload)
}

//To add payslip details
export const addPayslipDetails = (payload) => {
  return postRequest(payslipEndPoints.PAYSLIP, payload)
}

//To get payslip details
export const getAllPayslipDetails = (userId) => {
  return getRequest(payslipEndPoints.PAYSLIP_ADMIN + "/" + userId)
}

//To get particular payslip details
export const getPayslipByUserId = (userId, payslipId) => {
  return getRequest(payslipEndPoints.PAYSLIP_ADMIN + `/${userId}/${payslipId}`)
}

//To delete payslip details
export const deletePayslip = (userId, payslipId) => {
  return deleteRequest(payslipEndPoints.PAYSLIP + `/${userId}/${payslipId}`)
}

//To update payslip details
export const updatePayslipDetails = (payload) => {
  return putRequest(payslipEndPoints.PAYSLIP, payload)
}

//To add bonus details
export const addBonusDetails = (payload) => {
  return postRequest(bonusEndPoints.BONUS, payload)
}

//To get all bonus details
export const getAllBonusData = (userId) => {
  return getRequest(bonusEndPoints.BONUS_ADMIN + '/' + userId)
}

//To get particular bonus details
export const getBonusById = (userId, bonusId) => {
  return getRequest(bonusEndPoints.BONUS_ADMIN + `/${userId}/${bonusId}`)
}

//To update bonus details
export const updateBonusDetails = (payload) => {
  return putRequest(bonusEndPoints.BONUS, payload)
}

//To delete bonus details
export const deleteBonus = (userId, payslipId) => {
  return deleteRequest(bonusEndPoints.BONUS + `/${userId}/${payslipId}`)
}

//To add arrear details
export const addArrearDetails = (payload) => {
  return postRequest(arrrearEndPoints.ARREAR, payload)
}

//To update arrear details
export const updateArrearDetails = (payload) => {
  return putRequest(arrrearEndPoints.ARREAR, payload)
}

//To get all arrear details
export const getAllArrearDetails = (userId) => {
  return getRequest(arrrearEndPoints.ARREAR_ADMIN + '/' + userId)
}

//To get particular arrear details
export const getArrearById = (userId, arrearId) => {
  return getRequest(arrrearEndPoints.ARREAR_ADMIN + `/${userId}/${arrearId}`)
}

//To delete arrear details
export const deleteArrear = (userId, arrearId) => {
  return deleteRequest(arrrearEndPoints.ARREAR + `/${userId}/${arrearId}`)
}

//To add payoutpdf details
export const getPayoutDetailsPdf = (userId, month, year) => {
  return getDownloadRequest(payoutEndPoints.PAYOUT_ADMIN + `/${userId}/${month}/${year}/pdf`)
}
