import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess, setPermissions } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import {  signInApi } from 'apiServices/baseServices'
import { setOpenChangePassword } from 'store/base/commonSlice'
import store from 'store'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
			const resp = await signInApi(values)
			if (resp.success) {
				const { token , data } = resp?.response
				localStorage.setItem("token", token);
				dispatch(onSignInSuccess(token))
				if(data) {
					dispatch(setUser(data))
					dispatch(setOpenChangePassword(!data?.isUpdateRandomPassword))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
			}
			return resp;
		} catch (errors) {
			return errors
		}
    }

    const handleSignOut = ()  => {
		localStorage.removeItem("token");
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		
		handleSignOut()
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
        signOut
    }
}

export default useAuth

export const logOut=()=>{
	store.dispatch(setUser(initialState));
	store.dispatch(onSignOutSuccess());
	localStorage.removeItem('token');
	window.location.pathname=appConfig.authenticatedEntryPath
}