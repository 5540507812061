import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRolesApi } from "apiServices/baseServices";
import { setRoles, setRolesAndPermissions } from "./commonSlice";

export const fetchRoles = createAsyncThunk(
  "employee/fetchRolesDataAction",
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      getRolesApi()
        .then((resp) => {
          const roleData = resp?.response?.data || [];
          var arrWithDefault = [];
          var arrWithoutDefault = [];
          for (let item of roleData) {
            arrWithDefault.push({
              label: `${item?.name} ${item?.isDefault ? "(Default)" : ""}`,
              value: item?._id,
              isDefault: item?.isDefault,
            });
            arrWithoutDefault.push({label:`${item?.name}`,value:item?._id})
          }
          dispatch(setRoles({arrWithDefault,arrWithoutDefault}));
          dispatch(setRolesAndPermissions(roleData));
        })
        .catch((errors) => {
          dispatch(setRoles([]));
        });
    } catch (error) {
      dispatch(setRoles([]));
    }
  }
);
