export const permissions = {
    ADMIN: {
        VIEW_ADMIN_ROLE: 1,
        ADD_UPDATE_ADMIN_ROLE: 2,
        CREATE_COMPANY: 3,
        VIEWS_COMPANY: 4
    },
    COMPANY: {
        VIEW_COMPANY_ROLE: 5,
        ADD_UPDATE_COMPANY_ROLE: 6,
        CREATE_UPDATE_EMPLOYEE: 7,
        VIEWS_EMPLOYEE: 8,
        ACTION_EMPLOYEE: 9,
        VIEW_COMPANY: 10,
        UPDATE_COMPANY: 11,
        ACTION_LEAVE: 12,
        ADD_AND_UPDATE_LEAVE: 13,
        ASSIGN_LEAVE: 14,
        ADD_UPDATE_LEAVE_BALANCE: 15,
        VIEW_USER_LEAVES_INFO: 16,
        ACTION_ALL_LEAVE: 21,
        ADD_REMOVE_LEADS: 22,
        PAYOUT_ADD_UPDATE_DELETE: 23,
        USERS_PAYOUT_VIEW: 24
    },
    COMMON: {
        VIEW_PROFILE: 17,
        UPDATE_PROFILE: 18,
        UPLOAD_FILES: 19,
        GET_ROLES: 20
    }
}