import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    assignedEmployees:[],
    pagination:{},
    isLoading:false
}

export const leaveManagementSlice = createSlice({
	name: 'leave/leaveManagement',
	initialState,
	reducers: {
        setAssignedEmployees:(state,action)=>{
          state.assignedEmployees=action.payload?.data || [];
          state.pagination=action?.payload?.pagination || {};
        },
        setIsLoading:(state,action)=>{
          state.isLoading=action?.payload || false
        }
        
	},
})

export const { setAssignedEmployees,setIsLoading} = leaveManagementSlice.actions

export default leaveManagementSlice.reducer