import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    roles:[],
    roleList:[],
    rolesAndPermissions:[],
    openChangePassword:false
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setRoles:(state, action)=>{
            state.roles=action.payload?.arrWithDefault;
            state.roleList=action.payload?.arrWithoutDefault;
        },
        setRolesAndPermissions:(state,action)=>{
            state.rolesAndPermissions=action.payload
        },
        setOpenChangePassword:(state,action)=>{
         state.openChangePassword=action.payload
        }
	},
})

export const {setOpenChangePassword, setCurrentRouteKey,setRoles ,setRolesAndPermissions} = commonSlice.actions

export default commonSlice.reducer