import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import cn from './lang/cn.json'
import ab from './lang/ab.json'
import { themeConfig } from 'configs/theme.config'

const resources = {
    en: {
        translation: en
    },
    cn: {
        translation: cn
    },
    ab:{
        translation:ab
    }
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: themeConfig.locale,
    lng: themeConfig.locale,
    interpolation: {
        escapeValue: false 
    }
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    cn:()=>import('dayjs/locale/zh-cn'),
    ab:()=>import('dayjs/locale/ar')
}

export default i18n