import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  details: {},
  contacts: [],
  companyList: [],
  pagination:{},
  isLoading:false
};

export const companySlice = createSlice({
  name: "base/common",
  initialState,
  reducers: {
    setCompanyDetails: (state, action) => {
      const data = action.payload;

      if (data?.logo) data.logo = `${data?.logo}?timestamp=${Date.now()}`;
      data.thumbLogo = `${data?.thumbLogo}?timestamp=${Date.now()}`;
      data.previewLogo = `${data?.previewLogo}?timestamp=${Date.now()}`;
      if (data?.companyLeaveCalendar) {
        data.companyLeaveCalendar = `${
          data?.companyLeaveCalendar
        }?timestamp=${Date.now()}`;
        data.thumbCompanyLeaveCalendar = `${
          data?.thumbCompanyLeaveCalendar
        }?timestamp=${Date.now()}`;
        data.previewCompanyLeaveCalendar = `${
          data?.previewCompanyLeaveCalendar
        }?timestamp=${Date.now()}`;
      }
      state.details = data;
    },
    setCompanyContacts: (state, action) => {
      state.contacts = action.payload;
    },
    setCompanyList: (state, action) => {
      state.companyList = action.payload.data || [];
      state.pagination=action.payload.pagination || {}
    },
    updateCompanyContacts:(state,action)=>{
      const data=state.contacts;
        const index=data.indexOf(data?.filter(item=>item._id===action?.payload?._id)[0])
        if(index!==-1){
          data.splice(index,1,action.payload)
        }else{
          data.push(action.payload)
        }
        state.contacts=data
    },
    setIsLoading:(state,action)=>{
      state.isLoading=action?.payload || false
    }
  },
  
});

export const { setCompanyDetails, setCompanyContacts,setIsLoading, setCompanyList,updateCompanyContacts } =
  companySlice.actions;

export default companySlice.reducer;
