import { createSlice } from '@reduxjs/toolkit'
import { actions } from 'react-table'

export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
        token: '',
        signedIn: false,
        permissions:[]
    },
	reducers: {
        signInSuccess:(state,action)=>{
            state.user=action.payload
        },
        signInFailure:(state,action)=>{
            state.user=action.payload
        },
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
        },
        setToken: (state, action) =>  {
            state.token = action.payload
        },
        setPermissions:(state,action)=>{
            state.permissions=action.payload
        }
	},
})

export const {signInSuccess,setPermissions, signInFailure,signInStart,onSignInSuccess, onSignOutSuccess, setToken } = sessionSlice.actions

export default sessionSlice.reducer