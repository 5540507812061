import instance from "./api.config";

export const getRequest = (url) => {
  return instance.get(url);
};

export const postRequest = (url, payload) => {
  return instance.post(url, payload);
};

export const putRequest = (url, payload) => {
  return instance.put(url, payload);
};

export const deleteRequest = (url, payload) => {
  return instance.delete(url, payload);
};

export const patchRequest = (url, payload) => {
  return instance.patch(url, payload);
};

export const getDownloadRequest = (url, payload) => {
  return instance.get(url, {
    responseType: "blob",
  });
};
