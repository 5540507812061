import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  avatar: "",
  userName: "",
  email: "",
  authority: [],
  bankDetail: {},
  emergencyContacts: [],
  alternateContacts: [],
  profileImage:'',
  thumbProfileImage:'',
  previewProfileImage:''
};

export const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => {

      const data = action.payload;
      var permissions = [];

      for (let key of data?.roles || []) {
        permissions.push(...key?.permission);
      }
      permissions = [...new Set(permissions)];
      
      if (data?.profileImage){
        data.profileImage = `${data?.profileImage}?timestamp=${Date.now()}`;
        data.thumbProfileImage=`${data?.thumbProfileImage}?timestamp=${Date.now()}`
        data.previewProfileImage=`${data?.previewProfileImage}?timestamp=${Date.now()}`
      }
      return {
        ...data,
        avatar: "",
        userName: data?.firstName + " " + data?.lastName,
        authority: permissions,
      };
    },
    userLoggedOut: () => initialState,
    setUserBankDetail: (state, action) => {
      state.bankDetail = action.payload;
    },
    setUserEmergencyContacts: (state, action) => {
      state.emergencyContacts = action.payload;
    },
    setUserAlternateContact: (state, action) => {
      state.alternateContacts = action.payload;
    },
    setProfileImages:(state,action)=>{
     state.profileImage=`profiles/${action.payload}?timestamp=${Date.now()}`
     state.thumbProfileImage=`profiles/thumb_${action.payload}?timestamp=${Date.now()}`
     state.previewProfileImage=`profiles/preview_${action.payload}?timestamp=${Date.now()}`
    }
  },
});

export const {
  setUser,
  setUserBankDetail,
  setUserEmergencyContacts,
  setUserAlternateContact,
  setProfileImages
} = userSlice.actions;

export default userSlice.reducer;
